var site = site || {};
site.client = site.client || {};

(function() {
  document.addEventListener('lazybeforeunveil', function(e) {
    var isMobile = site.client.isMobile;
    var bg = e.target.getAttribute('data-bg');
    if (isMobile && !!e.target.getAttribute('data-bg-mobile')) {
      bg = e.target.getAttribute('data-bg-mobile');
    }
    if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')';
    }
  });
})();
